import { waitAuth } from "~/utils/middleware";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

import Abonnements = PBEnums.Abonnements;

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Abonnements.Drive);

    if (!__subscription().hasDrive && !await __subscription().isCanceled(PBEnums.Abonnements.Drive)) {
        log("❌ Has no Drive");
        return navigateTo("/");
    }

    log("✅ Has Drive or Drive is canceled");
});
